var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',{staticClass:"card"},[_c('router-link',{staticClass:"mainLink",attrs:{"to":{
      name: 'Info',
      params: {
        carData: _vm.car,
      },
    },"title":_vm.$t('info')}},[_c('img',{staticClass:"mainImg",attrs:{"src":require('@/assets/images/' + _vm.car.img),"onerror":("this.src='" + _vm.defaultImg + "'"),"alt":"Image"}})]),_c('router-link',{attrs:{"to":{
      name: 'Info',
      params: {
        carData: _vm.car,
      },
    }}},[_c('figcaption',[_c('h3',{staticClass:"cut-2line"},[_vm._v(_vm._s(_vm.car.name))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }