<template>
  <div class="home-component">
    <Slider />
    <div class="row">
      <div class="col-md-6" v-for="(car, index) in cars" :key="index">
        <CarCard :car="car" />
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@/components/general/Slider.vue";
import CarCard from "./CarCard.vue";

export default {
  name: "HomeComponent",
  components: {
    Slider,
    CarCard,
  },
  data() {
    return {
      //#region CARS
      cars: [
        {
          img: "car1.jpeg",
          name: " لفا روميو جوليا 2021",
          model: "سيدان",
          foul: "7",
          height: "140",
          type: "اتوماتيك",
          power: "200/4500",
          capacity: "2000cc",
          area: "480",
          number: "6",
          number1: "5",
        },
        {
          img: "car2.jpeg",
          name: "اودى A3 2022",
          model: "سيدان",
          foul: "7",
          height: "140",
          type: "اتوماتيك",
          power: "150/5000-6000",
          capacity: "1400cc",
          area: "455",
          number: "6",
          number1: "5",
        },
        {
          img: "car3.jpeg",
          name: "بى ام دبليو 118i",
          model: "هاتشباك",
          foul: "5.9",
          height: "140",
          type: " Dual Clutch",
          power: "140",
          capacity: "1500cc",
          area: "380",
          number: "6",
          number1: "5",
        },
        {
          img: "car4.jpeg",
          name: "إم جي 6",
          model: "سيدان",
          foul: "6.8",
          height: "140",
          type: " Dual Clutch",
          power: "169/5500",
          capacity: "1500cc",
          area: "424",
          number: "2",
          number1: "5",
        },
        {
          img: "car5.jpeg",
          name: "بورش كايين تيربو 2022",
          model: "SUV",
          foul: "9.2",
          height: "210",
          type: "اتوماتيك",
          power: "340/5300-6400",
          capacity: "3000cc",
          area: "772",
          number: "6",
          number1: "5",
        },
        {
          img: "car6.jpeg",
          name: "كيا جراند سيراتو 2022",
          model: "سيدان",
          foul: "6.9",
          height: "150",
          type: "اتوماتيك",
          power: "130/6300",
          capacity: "1600cc",
          area: "502",
          number: "6",
          number1: "5",
        },
        {
          img: "car7.jpeg",
          name: "كيا أكسيد 2022",
          model: "SUV",
          foul: "6.1",
          height: "184",
          type: "Dual Clutch",
          power: "160/5000",
          capacity: "1500cc",
          area: "426",
          number: "6",
          number1: "5",
        },
        {
          img: "car8.jpeg",
          name: "دي اس 3 كروس باك 2021",
          model: "SUV",
          foul: "4.9",
          height: "170",
          type: "اتوماتيك",
          power: "131/5500",
          capacity: "1200cc",
          area: "350",
          number: "6",
          number1: "5",
        },
        {
          img: "car9.jpeg",
          name: "فيات تيبو 2022",
          model: "سيدان",
          foul: "6.3",
          height: "150",
          type: "اتوماتيك",
          power: "110/5500",
          capacity: "1600cc",
          area: "520",
          number: "6",
          number1: "5",
        },
        {
          img: "car10.jpeg",
          name: " هوندا سيفيك 2021",
          model: "سيدان",
          foul: "5.4",
          height: "153",
          type: "اتوماتيك",
          power: "123/6500",
          capacity: "1600cc",
          area: "510",
          number: "6",
          number1: "5",
        },
      ],
      //#endregion CARS
    };
  },
  computed: {},
};
</script>

<style scoped lang="scss">
h2 {
  text-align: center;
  margin-bottom: 2rem;
}
</style>
